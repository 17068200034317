import PropTypes from "prop-types";
import * as React from "react";

import IconButton from "@mui/material/IconButton";

import ModeNightRoundedIcon from "@mui/icons-material/ModeNightRounded";
import WbSunnyRoundedIcon from "@mui/icons-material/WbSunnyRounded";
import { useTheme } from "@mui/material";
// import theme from "../../theme";

function ToggleColorMode({ mode, toggleColorMode }) {
  const theme = useTheme();
  return (
    <IconButton
      onClick={toggleColorMode}
      color="primary"
      aria-label="Theme toggle button"
      sx={{
        backgroundColor:
          mode === "light" ? theme.palette.grey[200] : theme.palette.grey[500],
      }}
    >
      {mode === "dark" ? (
        <WbSunnyRoundedIcon fontSize="small" />
      ) : (
        <ModeNightRoundedIcon fontSize="small" />
      )}
    </IconButton>
  );
}

ToggleColorMode.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default ToggleColorMode;
