import { Chip, FormLabel, Grid, Skeleton, Stack, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));
const slots = [
  {
    time: "08:30",
  },
  {
    time: "09:30",
  },
  {
    time: "10:30",
  },
  {
    time: "11:30",
  },
  {
    time: "12:30",
  },
  {
    time: "13:30",
  },
  {
    time: "14:30",
  },
  {
    time: "15:30",
  },
  {
    time: "16:30",
  },
  {
    time: "17:30",
  },
];
const workHoursInit = [
  {
    start: "09:00",
    end: "17:30",
    dayInWeek: 1,
    intervals: 30,
    breaks: [
      {
        start: "13:00",
        end: "14:00",
      },
      {
        start: "15:30",
        end: "17:00",
      },
    ],
  },
  {
    start: "08:30",
    end: "14:30",
    dayInWeek: 2,
  },
  {
    start: "10:00",
    end: "18:30",
    dayInWeek: 3,
  },
  {
    start: "10:00",
    end: "17:30",
    dayInWeek: 4,
  },
  {
    start: "09:00",
    end: "19:30",
    dayInWeek: 5,
    intervals: 120,
  },
  {
    start: "11:00",
    end: "14:30",
    dayInWeek: 6,
  },
];
const SelectDate = (props) => {
  const theme = useTheme();
  const { selectedProduct } = props;
  const [holidays, setHolidays] = useState([
    {
      dayInWeek: 0,
    },
    {
      holiday: {
        seconds: 1716570393,
      },
    },
  ]);
  const [workHours, setWorkHours] = useState(workHoursInit);
  const [timeSlots, setTimeSlots] = useState();
  const [alreadySelectedByClients, setAlreadySelectedByClients] = useState([
    slots[0],
  ]);
  const [userSelectedAppointment, setUserSelectedAppointment] = useState({
    date: new Date(),
  });

  const handleSplitToTimeSlots = (dayInWeek) => {
    const indexInWorkHours = workHours.findIndex(
      (el) => el.dayInWeek === dayInWeek
    );

    if (indexInWorkHours > -1) {
      const {
        start,
        end,
        intervals = 60,
        breaks = [],
      } = workHours[indexInWorkHours];
      const startTime = moment(start, "HH:mm");
      const endTime = moment(end, "HH:mm");
      const timeSeries = [];

      while (startTime.isBefore(endTime)) {
        const slotStart = startTime.clone();
        const slotEnd = slotStart.clone().add(intervals, "minutes");

        const isInBreak = breaks.some((breakPeriod) => {
          const breakStart = moment(breakPeriod.start, "HH:mm");
          const breakEnd = moment(breakPeriod.end, "HH:mm");
          return (
            slotStart.isBetween(breakStart, breakEnd, null, "[)") ||
            slotEnd.isBetween(breakStart, breakEnd, null, "(]") ||
            (slotStart.isSameOrAfter(breakStart) &&
              slotEnd.isSameOrBefore(breakEnd))
          );
        });

        const slotDuration = slotEnd.diff(slotStart, "minutes");
        const isSlotDurationEnough = slotDuration >= selectedProduct.duration;

        if (
          !isInBreak &&
          isSlotDurationEnough &&
          slotEnd.isSameOrBefore(endTime)
        ) {
          timeSeries.push({
            time: slotStart.format("HH:mm"),
            disabled: false,
          });
        }

        startTime.add(intervals, "minutes");
      }
      setTimeSlots(timeSeries);
    }
  };

  const handleChangeDateValue = (newVal) => {
    setTimeSlots();
    setUserSelectedAppointment({
      date: moment(newVal).toDate(),
    });
  };

  useEffect(() => {
    if (userSelectedAppointment && !timeSlots) {
      handleSplitToTimeSlots(moment(userSelectedAppointment.date).day());
    }
  }, [userSelectedAppointment, timeSlots]);
  return (
    <Grid container spacing={2}>
      <FormGrid
        item
        xs={12}
        sx={{
          justifyContent: "center",
          display: "flex",
        }}
        sm={9}
        md={7}
        xl={6}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateCalendar
            sx={{
              m: "auto",
            }}
            value={moment(userSelectedAppointment.date) || new Date()}
            onChange={(val) => {
              handleChangeDateValue(val);
              // handleSplitToTimeSlots(moment(val).day());
            }}
            disablePast
            showDaysOutsideCurrentMonth
            reduceAnimations
            shouldDisableDate={(day) => {
              const dayInWeek = moment(day).day();
              const indexInHolidays = holidays.findIndex(
                (el) =>
                  el.holiday &&
                  el.holiday.seconds &&
                  moment(moment(el.holiday.seconds * 1000).toDate()).isSame(
                    day,
                    "day"
                  )
              );
              if (
                holidays &&
                holidays.findIndex((el) => el.dayInWeek === dayInWeek) > -1
              ) {
                return true;
              }
              if (holidays && indexInHolidays > -1) {
                return true;
              }
              return false;
            }}
          />
        </LocalizationProvider>
      </FormGrid>
      <FormGrid item xs={12} sm={3} md={12} xl={6}>
        <FormLabel htmlFor="time-slot" required>
          בחירת שעה
        </FormLabel>
        <Stack sx={{ flexDirection: "row", flexWrap: "wrap", gap: 1 }}>
          {timeSlots && timeSlots.length > 0
            ? timeSlots.map((slot, i) => {
                const isSelected =
                  userSelectedAppointment.time &&
                  userSelectedAppointment.time === slot.time;
                const isDisabled =
                  (alreadySelectedByClients &&
                    alreadySelectedByClients.length > 0 &&
                    alreadySelectedByClients.findIndex(
                      (el) => el.time === slot.time
                    ) > -1) ||
                  slot.disabled;
                return (
                  <Chip
                    variant={isSelected ? "contained" : "outlined"}
                    clickable={!isDisabled}
                    disabled={isDisabled}
                    color="primary"
                    key={i}
                    label={slot.time}
                    sx={{
                      minWidth: "65px",
                      boxShadow: isSelected && theme.shadows[2],
                    }}
                    onClick={() => {
                      if (!isDisabled) {
                        setUserSelectedAppointment({
                          ...userSelectedAppointment,
                          time: slot.time,
                        });
                      }
                    }}
                  />
                );
              })
            : timeSlots && timeSlots.length === 0
            ? "no available time"
            : [1, 2, 3, 4].map((skl) => (
                <Skeleton variant="rounded" sx={{ width: 65 }} key={skl} />
              ))}
        </Stack>
      </FormGrid>
    </Grid>
  );
};
export default SelectDate;
