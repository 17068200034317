import {
  InsertInvitationRounded,
  Payments,
  SendRounded,
  StarRounded,
} from "@mui/icons-material";
import {
  airbnb,
  binance,
  coinbase,
  dropbox,
  facebook,
  instagram,
  linkedin,
  people01,
  people02,
  people03,
  twitter,
} from "../assets";

export const navLinks = [
  {
    id: "home",
    title: "ראשי",
  },
  {
    id: "features",
    title: "למה אנחנו?",
  },
  {
    id: "clients",
    title: "לקוחות מספרים",
  },
  {
    id: "contact",
    title: "יצירת קשר",
  },
  {
    href: "login?redirectTo=/business/cards",
    title: "כניסה",
    id: "login",
  },
];

export const features = [
  {
    id: "feature-3",
    icon: Payments,
    title: "מחיר סביר",
    content: "קבל את התמורה הטובה ביותר לכספך עם התוכניות התמחור שלנו.",
  },
  {
    id: "feature-2",
    icon: SendRounded,
    title: "קל לשימוש",
    content: "הממשק שלנו קל לשימוש וידידותי למשתמש, גם ללא ניסיון קודם.",
  },
  {
    id: "feature-1",
    icon: StarRounded,
    title: "התאמה אישית",
    content: "צור כרטיס שמייצג את המותג שלך עם כלים קלים לשימוש להתאמה אישית.",
  },
  {
    id: "feature-4",
    icon: InsertInvitationRounded,
    title: "ניהול תורים",
    content: "בקליק אחד, תוכל להתחיל לנהל את התורים של העסק שלך דרך המערכת.",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "כרטיסי הביקור הדיגיטלי הזה הוא מהפכני! קל לשימוש ועזר לי לצור קשרים רבים חדשים.",
    name: "יונתן",
    title: "יזם",
    img: people03,
  },
  {
    id: "feedback-2",
    content:
      "במחיר זול בהרבה יחסית לשוק אני מלציח לעצב את הכרטיס שלי כך שיתאים למותג של העסק שלי בצורה מושלמת ובקלות.",
    name: "רותי",
    title: "עצמאית",
    img: people02,
  },
  {
    id: "feedback-3",
    content:
      "האינטגרציה עם מודול הפגישות שיפרה מאוד את תפעול העסק שלי והפחיתה לי את ההתעסקות עם היומן והתורים.",
    name: "רימא",
    title: "בעלת מכון יופי",
    img: people01,
  },
];

const month = new Date().getMonth() + 1;
const year = new Date().getFullYear();
const num = Math.round(month * year * 0.55);

export const stats = [
  {
    id: "stats-1",
    title: "משתמשים פעילים",
    value: num,
    prefix: "",
    suffix: "+",
  },
  {
    id: "stats-2",
    title: "כרטיסי ביקור",
    value: "12",
    prefix: "",
    suffix: "K+",
  },
  {
    id: "stats-3",
    title: "אינטראקציות",
    value: "600",
    prefix: "",
    suffix: "K+",
  },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "Content",
        link: "https://www.hoobank.com/content/",
      },
      {
        name: "How it Works",
        link: "https://www.hoobank.com/how-it-works/",
      },
      {
        name: "Create",
        link: "https://www.hoobank.com/create/",
      },
      {
        name: "Explore",
        link: "https://www.hoobank.com/explore/",
      },
      {
        name: "Terms & Services",
        link: "https://www.hoobank.com/terms-and-services/",
      },
    ],
  },
  {
    title: "Community",
    links: [
      {
        name: "Help Center",
        link: "https://www.hoobank.com/help-center/",
      },
      {
        name: "Partners",
        link: "https://www.hoobank.com/partners/",
      },
      {
        name: "Suggestions",
        link: "https://www.hoobank.com/suggestions/",
      },
      {
        name: "Blog",
        link: "https://www.hoobank.com/blog/",
      },
      {
        name: "Newsletters",
        link: "https://www.hoobank.com/newsletters/",
      },
    ],
  },
  {
    title: "Partner",
    links: [
      {
        name: "Our Partner",
        link: "https://www.hoobank.com/our-partner/",
      },
      {
        name: "Become a Partner",
        link: "https://www.hoobank.com/become-a-partner/",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://www.twitter.com/",
  },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/",
  },
];

export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];
