import * as React from "react";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const addresses = ["1 MUI Drive", "Reactville", "Anytown", "99999", "USA"];

export default function Review({ selectedProduct }) {
  const payments = [
    { name: "שם העסק:", detail: selectedProduct.businessName },
    { name: "עיר:", detail: "סכנין" },
    { name: "מספר טלפון:", detail: "0123456789" },
    { name: "מיקום:", detail: "" },
  ];
  return (
    <Stack spacing={2}>
      <List disablePadding sx={{ textAlign: "inherit" }}>
        <ListItem sx={{ py: 1, px: 0, textAlign: "inherit" }}>
          <ListItemText primary="קבעת תור ל-" secondary={selectedProduct.name} />
          <Typography variant="body2">{`${selectedProduct.price} ש"ח`}</Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText
            secondary={`* התשלום יתבצע מול ${selectedProduct.businessName}`}
          />
          {/* <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            $144.97
          </Typography> */}
        </ListItem>
      </List>
      <Divider />
      <Stack
        direction="column"
        divider={<Divider flexItem />}
        spacing={2}
        sx={{ my: 2 }}
      >
        <div>
          <Typography variant="subtitle2" gutterBottom>
            הפרטים שלך
          </Typography>
          <Typography gutterBottom>John Smith</Typography>
          <Typography color="text.secondary" gutterBottom>
            {addresses.join(", ")}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2" gutterBottom>
            פרטי העסק
          </Typography>
          <Grid container>
            {payments.map((payment) => (
              <React.Fragment key={payment.name}>
                <Stack
                  direction="row"
                  spacing={1}
                  useFlexGap
                  sx={{ width: "100%", mb: 1 }}
                >
                  <Typography variant="body1" color="text.secondary">
                    {payment.name}
                  </Typography>
                  <Typography variant="body2">{payment.detail}</Typography>
                </Stack>
              </React.Fragment>
            ))}
          </Grid>
        </div>
      </Stack>
    </Stack>
  );
}

Review.propTypes = {
  selectedProduct: PropTypes.object.isRequired,
};
