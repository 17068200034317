import { Icon } from "@mui/material";
import React from "react";
import { getOrDefault } from "../../../Helpers/Utils";
import styles from "../style";

const Footer = ({ spec, type, index }) => {
  const logoSource = getOrDefault(spec.logoSource, null);
  const sloganText = getOrDefault(spec.sloganText, "");
  const sloganTextColor = getOrDefault(spec.sloganTextColor, "#fff");

  const startMainText = getOrDefault(spec.startMainText, "")
  const startSecondaryText = getOrDefault(spec.startSecondaryText, "")

  const backgroundColor = getOrDefault(spec.backgroundColor, "#000");

  const socialMedia = getOrDefault(spec.socialMedia, []);

  return (
    <footer style={{ background: backgroundColor }} className={`${styles.flexCenter} ${styles.paddingY} ${styles.paddingX} flex-col`}>
      <div className="flex flex-col items-center justify-between w-full md:flex-row pt-10">
        <p className="font-poppins font-normal text-center text-[18px] leading-[27px] text-white">
          {startMainText}
          <br />
          {startSecondaryText}
        </p>
        <div className="flex gap-3 flex-row mt-6 md:mt-0">
          {
            socialMedia.map((social, socialIndex) => (
              <a href={`${social.fullLink}`} target="_blank" rel="noreferrer" key={`${type}-${index}-${socialIndex}-a-element`}>
                <Icon
                  key={`${type}-${index}-${socialIndex}-icon`}
                  sx={{
                    color: "#fff",
                    width: "21px",
                    height: "21px",
                    cursor: "pointer"
                  }}
                >{social.icon}</Icon>
              </a>
            ))
          }
        </div>
        <div className="flex flex-row mt-6 md:mt-0">
          <div
            className={`${styles.flexStart}  md:flex-row flex-col mb-2 w-full`}
          >
            <div className="flex flex-col justify-start flex-1 mr-10">
              <img
                src={logoSource}
                alt="Flow Logo"
                className="w-[266px] h-[92px] object-cover"
              />
              <p
                className={`${styles.paragraph} max-w-[300px] mt-2 ml-0 md:ml-2`}
                style={{
                  color: sloganTextColor
                }}
              >
                {sloganText}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
