import { motion } from "framer-motion";
import React, { useRef, useState } from "react";
import styles from "../style";
// import Button from "./Button";
import { useTheme } from "@emotion/react";
import { Button, CircularProgress, lighten } from "@mui/material";
import { getOrDefault } from "../../../Helpers/Utils";
import FormInput from "./FormInput";
import { useMicrospecDynamicUIContext } from "../../../GlobalProvider";

const scaleUpVariants = {
    hidden: {
        opacity: 0,
        scale: 0.5,
    },
    whileInView: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.5,
        },
    },
    viewport: { once: true },
};

const ContactForm = ({ spec, type, index }) => {
    const form = useRef();
    const theme = useTheme();
    
    const { cloudProvider } = useMicrospecDynamicUIContext();

    const { submitFormWithPathAndValues } = cloudProvider;

    // states to update DOM and feedback message after clicking on button
    const [loading, setLoading] = useState(false);
    const [hasBeenSent, setHasBeenSent] = useState(false);
    const [messageFeedback, setMessageFeedback] = useState("");

    const formIdentifier = spec.formIdentifier;
    if (!formIdentifier) {
        throw new Error("formIdentifier is required");
    }
    const formPath = `/forms/${formIdentifier}/instances`

    const mainTitleText = getOrDefault(spec.mainTitleText, "Main Title Goes Here");
    const mainTitleColor = getOrDefault(spec.mainTitleColor, "#fff");

    const secondaryTitleText = getOrDefault(spec.secondaryTitleText, "Secondary Title Goes Here");
    const secondaryTitleColor = getOrDefault(spec.secondaryTitleColor, "#fff");

    const textBeforeSubmitButton = getOrDefault(spec.textBeforeSubmitButton, "After sending the message, we will get back to you as soon as possible");

    const submitButtonColor = getOrDefault(spec.submitButtonColor, "#f2f2f2");
    const submitButtonText = getOrDefault(spec.submitButtonText, "Submit");

    const clearButtonColor = getOrDefault(spec.clearButtonColor, "#f2f2f2");
    const clearButtonText = getOrDefault(spec.clearButtonText, "Clear");

    const successMessage = getOrDefault(spec.successMessage, "Thank you for contacting us! We will get back to you as soon as possible");
    const failureMessage = getOrDefault(spec.failureMessage, "Something went wrong! Please reload the page and try again.");

    const inputs = getOrDefault(spec.inputs, []);
    const processedInputs = {};
    inputs.forEach((input) => {
        processedInputs[input.name] = "";
    });
    const copyProcessedInputs = { ...processedInputs };

    // state as object to maintain form's input
    const [values, setValues] = useState(processedInputs);

    // update a value everytime it is changed
    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const resetForm = (e) => {
        setValues(copyProcessedInputs);
    };

    // function to update text to render after sent email
    const updateFeedback = (submittedSuccessfully) => {
        return submittedSuccessfully ? successMessage : failureMessage;
    };

    const submitForm = async (e) => {
        e.preventDefault();
        setLoading(true);
        const response = await submitFormWithPathAndValues(formPath, values);
        setMessageFeedback(updateFeedback(response));
        setHasBeenSent(true);
        setLoading(false);
    };

    return (
        <section
            className={`${styles.flexCenter} ${styles.padding} sm:flex-row flex-col box-shadow items-center `}
            id="contact"
            style={{
                background: "#000000"
            }}
        >
            {/* w-full to contain input field into div without overflowing */}
            <motion.div
                className="flex flex-col flex-1 w-full p-5"
                variants={scaleUpVariants}
                initial={scaleUpVariants.hidden}
                whileInView={scaleUpVariants.whileInView}
                viewport={{ once: true }}
            >
                <h2 className={`${styles.heading2}`} style={{ color: mainTitleColor }}>{mainTitleText}</h2>
                <p className={`${styles.paragraph}`} style={{ color: secondaryTitleColor }}>
                    {secondaryTitleText}
                </p>
                {
                    loading && <CircularProgress />
                }
                {
                    !loading && !hasBeenSent && (
                        <div>
                            <form ref={form} onSubmit={submitForm} className="my-5">
                                {
                                    inputs.map((input, inputIndex) => (
                                        <FormInput
                                            key={`${type}-${index}-${inputIndex}-form-input`}
                                            {...input}
                                            value={values[input.name]}
                                            onChange={onChange}
                                        />
                                    ))
                                }
                                <h4 className="font-medium font-poppins my-2"
                                    style={{
                                        color: secondaryTitleColor
                                    }}>
                                    {textBeforeSubmitButton}
                                </h4>
                                <Button
                                    type="submit"
                                    sx={{
                                        background: submitButtonColor,
                                        color: theme.palette.getContrastText(submitButtonColor),
                                        "&:hover": {
                                            background: lighten(submitButtonColor, 0.3),
                                        },
                                        mx: 1
                                    }}>{submitButtonText}</Button>
                                <Button
                                    type="reset"
                                    variant="contained"
                                    sx={{
                                        background: clearButtonColor,
                                        color: theme.palette.getContrastText(clearButtonColor),
                                        "&:hover": {
                                            background: lighten(clearButtonColor, 0.3),
                                        },
                                        mx: 1
                                    }}
                                    onClick={resetForm}>{clearButtonText}</Button>
                            </form>
                        </div>
                    )
                }
                {
                    !loading && hasBeenSent && (
                        <h4 className="font-poppins font-semibold text-[20px] leading-[32px] mt-5"
                            style={{
                                color: secondaryTitleColor
                            }}
                        >
                            {messageFeedback}
                        </h4>
                    )
                }
            </motion.div>
        </section>
    );
}

export default ContactForm;