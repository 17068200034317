import {
  Button,
  CircularProgress,
  FormHelperText,
  useTheme,
} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/system";
import { useState } from "react";
import checked from "../../../assets/checked.gif";
// import theme from "../../theme";

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export default function AddressForm() {
  const theme = useTheme();
  const [isSending, setIsSending] = useState();
  const sendVerifyPhoneNumberCode = () => {
    setIsSending("loading");
    setTimeout(() => {
      setIsSending("sent");
      setTimeout(() => {
        setIsSending("success");
      }, 2000);
    }, 2000);
  };
  return (
    <Grid container spacing={3}>
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="first-name" required>
          שם פרטי
        </FormLabel>
        <OutlinedInput
          id="first-name"
          name="first-name"
          type="name"
          placeholder="John"
          autoComplete="first name"
          required
        />
      </FormGrid>
      <FormGrid item xs={12} md={6}>
        <FormLabel htmlFor="last-name" required>
          שם משפחה
        </FormLabel>
        <OutlinedInput
          id="last-name"
          name="last-name"
          type="last-name"
          placeholder="Snow"
          autoComplete="last name"
          required
        />
      </FormGrid>
      <FormGrid item xs={12}>
        <FormLabel htmlFor="address1">כתובת</FormLabel>
        <OutlinedInput
          id="address1"
          name="address1"
          type="address1"
          placeholder="רחוב, מס ועיר"
          autoComplete="address-line1"
        />
      </FormGrid>
      <FormGrid item xs={12}>
        <FormLabel htmlFor="email">דוא"ל</FormLabel>
        <OutlinedInput
          id="email"
          name="email"
          type="email"
          placeholder="דואר אלקטרוני"
          autoComplete="address-line1"
        />
      </FormGrid>
      <FormGrid item xs={12} sm={8}>
        <FormLabel htmlFor="phone" required>
          מספר טלפון
        </FormLabel>
        <OutlinedInput
          id="phone"
          name="phone"
          type="phone"
          placeholder="01234567"
          autoComplete="phone"
          required
          disabled={isSending === "loading" || isSending === "sent"}
          endAdornment={
            isSending === "loading" ? (
              <CircularProgress size={25} sx={{ ml: 0.5 }} />
            ) : isSending === "sent" ? (
              <img src={checked} width={25} height={25} />
            ) : (
              <Button
                color={isSending === "success" ? "success" : "primary"}
                onClick={() => sendVerifyPhoneNumberCode()}
              >
                שלח קוד
              </Button>
            )
          }
        />
        <FormHelperText
          sx={{
            textAlign: "inherit",
            color: isSending === "success" && theme.palette.success.main,
          }}
        >
          לחץ על שלח קוד כדי לאמת את הטלפון
        </FormHelperText>
      </FormGrid>
      <FormGrid item xs={12} sm={4}>
        <FormLabel htmlFor="code" required>
          קוד אימות
        </FormLabel>
        <OutlinedInput
          id="code"
          name="code"
          type="text"
          placeholder="1234"
          autoComplete="off"
          required
        />
      </FormGrid>
      {/* <FormGrid item xs={12}>
        <FormControlLabel
          control={<Checkbox name="saveAddress" value="yes" />}
          label="Use this address for payment details"
        />
      </FormGrid> */}
    </Grid>
  );
}
