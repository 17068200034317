import { Box, Divider } from "@mui/material";
import { getOrDefault } from "../../../Helpers/Utils";

const SectionsDivider = ({ spec, type, index }) => {
    const baseBackground = getOrDefault(spec.baseBackground, "#3f3e45");
    const dividerBackground = getOrDefault(spec.dividerBackground, "#3f3e45");
    const width = getOrDefault(spec.width, "100%");

    return (
        <Box sx={{
            background: baseBackground
        }}
            key={`${type}-${index}-box`}>
            <Divider
                key={`${type}-${index}-divider`}
                sx={{
                    background: dividerBackground,
                    mx: "auto",
                    width,
                }} />
        </Box>

    )
}

export default SectionsDivider;