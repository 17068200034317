import { useTheme } from "@mui/material";
import { PropTypes } from "prop-types";
import { createContext, useContext, useMemo, useState } from "react";
import getCustomThemeInitialState from "../../components/BusinessCard/customThemeInitialState";

const BusinessCardDetailsContext = createContext();

export const BusinessCardProvider = ({ children }) => {
  BusinessCardProvider.propTypes = {
    children: PropTypes.any,
  };

  const theme = useTheme();
  const initState = getCustomThemeInitialState(theme);
  const [businessCardDetails, setBusinessCardDetails] = useState();
  const [businessCardTheme, setBusinessCardTheme] = useState(initState);
  const memoBusinessDetails = useMemo(
    () => ({
      businessCardDetails,
      setBusinessCardDetails,
      businessCardTheme,
      setBusinessCardTheme,
    }),
    [businessCardDetails, setBusinessCardDetails, businessCardTheme, setBusinessCardTheme]
  );

  return (
    <BusinessCardDetailsContext.Provider value={memoBusinessDetails}>
      {children}
    </BusinessCardDetailsContext.Provider>
  );
};
export const useBusinessDetails = () => useContext(BusinessCardDetailsContext);
