import React from "react";
import { logo } from "../../pages/BusinessCardLandingPage/assets";
import "../../pages/BusinessCardLandingPage/index.css";
import styles from "../../pages/BusinessCardLandingPage/style";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className={`${styles.flexCenter} ${styles.paddingY} flex-col`}>
      <div className="flex flex-col items-center justify-between w-full md:flex-row pt-6 border-t-[1px] border-t-[#3f3e45]">
        <p className="font-poppins font-normal text-center text-[18px] leading-[27px] text-white">
          {`כל הזכויות שמורים ${year} ©`}
          <br />
          <a
            href="https://flowlogisti.com"
            className="cursor-pointer hover:text-secondary"
            rel="noopener noreferrer"
            target="_blank"
            style={{
              direction: "ltr",
            }}
          >
            ❤️ By FlowLogisti with 
          </a>
        </p>
        <div className="flex flex-row mt-6 md:mt-0">
          <div
            className={`${styles.flexStart}  md:flex-row flex-col mb-2 w-full`}
          >
            <div className="flex flex-col justify-start flex-1 mr-10">
              <img
                src={logo}
                alt="hoobank logo"
                className="w-[266px] h-[72px] object-contain"
              />
              <p
                className={`${styles.paragraph} max-w-[310px] mt-4 ml-0 md:ml-2`}
              >
                כרטיס ביקור דיגיטלי מותאם אישית.
              </p>
            </div>
          </div>
          {/* {socialMedia.map((social, index) => (
            <img
              src={social.icon}
              key={social.id}
              alt={social.id}
              className={`${
                index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
              } w-[21px] h-[21px] object-contain cursor-pointer`}
              onClick={() => window.open(social.link)}
            />
          ))} */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
