import { Icon } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import { useMicrospecThemeLanguageContext } from "../../../MicrospecDynamicUI/Themes";
import { features } from "../../pages/BusinessCardLandingPage/constants/index";
import "../../pages/BusinessCardLandingPage/index.css";
import styles, { layout } from "../../pages/BusinessCardLandingPage/style";
import Button from "./Button";

const featureCardVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.2,
    },
  },
};

const FeatureCard = ({ icon, title, content, index }) => {
  const CardIcon = icon;
  return (
    <motion.div
      className={`flex flex-row items-center p-6 rounded-[20px] ${
        index !== features.length - 1 ? "mb-6" : "mb-0"
      } feature-card`}
      variants={featureCardVariants}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true }}
    >
      {/* ICON AND ITS CIRCLE */}
      <div
        className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-dimBlue`}
      >
        <Icon
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#232f3e",
          }}
        >
          <CardIcon className="w-[50%] h-[50%] object-contain" />
        </Icon>
      </div>
      {/* CONTENT */}
      <div className="flex flex-col flex-1 mr-3">
        <h4 className="font-semibold text-white font-poppins text-[18px] leading-[23px] mb-1">
          {title}
        </h4>
        <p className="font-normal text-dimWhite font-poppins text-[16px] leading-[24px] mb-1">
          {content}
        </p>
      </div>
    </motion.div>
  );
};

const Business = () => {
  const { localizationProvider } = useMicrospecThemeLanguageContext();
  return (
    <section id="features" className={layout.section}>
      {/* HEADING + INFO */}
      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}>
          {localizationProvider.translateByType({
            type: "Localized",
            text: "business-cards.innovativeApproach",
          })}, <br className="hidden sm:block" /> {localizationProvider.translateByType({
                type: "Localized",
                text: "business-cards.modernSolution",
              })}.
        </h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          {localizationProvider.translateByType({
            type: "Localized",
            text: "business-cards.whyUsDesc",
          })}
        </p>
        <Button
          styles="mt-10"
          type="button"
          text={`${localizationProvider.translateByType({
            type: "Localized",
            text: "business-cards.get",
          })} ${localizationProvider.translateByType({
            type: "Localized",
            text: "business-cards.started",
          })}`}
        />
      </div>
      {/* map over features */}
      <div
        className={`${layout.sectionImg} flex-col`}
        style={{
          alignItems: "flex-start",
        }}
      >
        {features.map((feature, index) => (
          <FeatureCard key={feature.id} {...feature} index={index} />
        ))}
      </div>
    </section>
  );
};

export default Business;
