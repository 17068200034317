import { motion } from "framer-motion";
import React from "react";
// import CountUp from "../../../Components/CountUp/CountUp";
import CountUp from "react-countup";
import { getOrDefault } from "../../../Helpers/Utils";
import styles from "../style";

const statsVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.2,
    },
  },
};

const statVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};



const Statistics = ({ spec, type, index }) => {
  const statistics = getOrDefault(spec.statistics, []);
  const titleTextColor = getOrDefault(spec.titleTextColor, "#fff");
  const valuePrefixSuffixTextColor = getOrDefault(spec.valuePrefixSuffixTextColor, "#fff");

  const keyBase = `${type}-${index}`
  return (
    <section
      key={keyBase}
      className={`${styles.flexCenter} flex-row flex-wrap sm:mb-15 py-120 pt-10 pb-10`}
      style={{
        background: "black",
        minHeight: "120px",
      }}
    >

      {statistics.map((stat, statIndex) => (
        <motion.div
          key={`${keyBase}-${statIndex}-element`}
          variants={statVariants}
        >
          <CountUp
            start={0}
            end={stat.value}
            delay={0.5}
            prefix={stat.prefix}
            suffix={stat.suffix}
            enableScrollSpy={true}
            scrollSpyOnce={true}
          >
            {({ countUpRef }) => (
              <motion.div
                className="flex flex-row items-center justify-start flex-1 m-3 sm:justify-center"
                variants={statsVariants}
                initial="hidden"
                whileInView="show"
              >
                <h4
                  ref={countUpRef}
                  className="font-poppins font-semibold xs:text-[40px] text-[30px] xs:leading-[53px] leading-[43px]"
                  style={{
                    color: valuePrefixSuffixTextColor,
                  }}
                >
                </h4>
                <p
                  className="font-poppins font-normal xs:text-[20px] text-[15px] xs:leading-[26px] leading-[21px] uppercase ml-3 whitespace-nowrap"
                  style={{
                    // background: "linear-gradient(27deg, rgba(29,29,250,0.51) 0%, rgba(203,0,255,0.51) 100%)",
                    color: titleTextColor,
                  }}
                >
                  {stat.title}
                </p>
              </motion.div>
            )}
          </CountUp>
        </motion.div>
      ))}
    </section>
  );
};

export default Statistics;
