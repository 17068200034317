import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getOrDefault } from "../../Helpers/Utils";
import { useMicrospecThemeLanguageContext } from "../../Themes";
import DrawerListChildrenItems from "./DrawerListChildrenItems";

const DrawerListParentItem = ({ value, data }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { localizationProvider } = useMicrospecThemeLanguageContext();
    const { mobileOpen, handleDrawerToggle, dashboard, item } = data
    const childrenItems = getOrDefault(value.specs, []);
    const hasChildrenItems = childrenItems.length > 0;
    const childrenItemsPaths = childrenItems.map((item) => item.fullPath);
    const [childrenOpen, setChildrenOpen] = useState(childrenItemsPaths.includes(item.fullPath));

    useEnhancedEffect(() => {
        if (childrenOpen && !childrenItemsPaths.includes(location.pathname)) {
            setChildrenOpen(false);
        }
    }, [location.pathname]);

    return (
        <List
            sx={{ width: '100%', maxWidth: 360, my: 0, py: 0.5 }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            <ListItemButton
                id={value.path + "list-item-button"}
                key={value.path + "list-item-button"}
                name={value.path + "list-item-button"}
                onClick={() => {
                    if (!hasChildrenItems) {
                        if (mobileOpen) {
                            handleDrawerToggle()
                        }
                        if (value.path !== item.path) {
                            navigate(dashboard.path + "/" + value.path, { replace: true })
                        }
                    } else {
                        setChildrenOpen(!childrenOpen)
                    }
                }}
                selected={item.path === value.path}
            >
                <ListItemIcon>
                    {value.icon}
                </ListItemIcon>
                <ListItemText
                    id={value.path + "list-item-text"}
                    key={value.path + "list-item-text"}
                    name={value.path + "list-item-text"}
                    primary={localizationProvider.translateByType(value.name)}
                    style={{display: "flex", justifyContent:"flex-start"}}
                    />
                <ListItemIcon>
                    {hasChildrenItems && childrenOpen && <ExpandLess />}
                    {hasChildrenItems && !childrenOpen && <ExpandMore />}
                </ListItemIcon>
            </ListItemButton>
            {hasChildrenItems && <DrawerListChildrenItems data={{ parent: value, childrenItems, childrenOpen, setChildrenOpen }} />}
        </List>
    )
};

export default DrawerListParentItem;