import { ArrowForwardRounded } from "@mui/icons-material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import { collectionGroup, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMicrospecDynamicUIContext } from "../../../MicrospecDynamicUI/GlobalProvider";
import logo from "../../consts/logo";
import { useBusinessDetails } from "../../hooks/useBusinessCardDetails/useBusinessCardDetails";
import AddressForm from "./AddressForm";
import Info from "./Info";
import InfoMobile from "./InfoMobile";
import Review from "./Review";
import SelectDate from "./SelectDate";
import ToggleColorMode from "./ToggleColorMode";
import getCheckoutTheme from "./getCheckoutTheme";

const steps = ["בחירת תור", "הפרטים שלך", "במרחק לחיצה!"];

const logoStyle = {
  width: "140px",
  height: "56px",
  marginLeft: "-8px",
  marginRight: "-4px",
};

function getStepContent(step, props) {
  switch (step) {
    case 0:
      return <SelectDate {...props} />;
    case 1:
      return <AddressForm {...props} />;
    case 2:
      return <Review {...props} />;
    default:
      throw new Error("Unknown step");
  }
}

export default function Checkout() {
  const theme = useTheme();
  const matchSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const { cloudProvider } = useMicrospecDynamicUIContext();
  const { firestore } = cloudProvider;
  const { cardId } = useParams();
  const [mode, setMode] = useState("light");
  const checkoutTheme = createTheme(getCheckoutTheme(mode));
  const [selectedProduct, setSelectedProduct] = useState({
    name: "תספורת",
    price: 300,
    duration: 30,
    desc: "תספורת רגילה בלי זקן",
    businessName: "Saloni",
    cardId: "123",
  });
  const [activeStep, setActiveStep] = useState(0);
  const { businessCardDetails } = useBusinessDetails();

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const retrieveBusinessDetailsByCardId = async (card_id) => {
    const cardsRef = collectionGroup(firestore.instance, "cards");
    const q = query(cardsRef, where("cardId.text", "==", card_id));
    const card = getDocs(q).then((cardsDocs) => {
      if (cardsDocs.empty) {
        return null;
      }
      const [firstDoc] = cardsDocs.docs;
      const business_id = firstDoc.ref.parent.parent.id;
      console.log(business_id);
      return {
        ...firstDoc.data(),
        id: firstDoc.id,
        business_id,
      };
    });
  };

  const handleGetBusinessDetailsByCardId = async (businessCardId) => {
    const details = await retrieveBusinessDetailsByCardId(businessCardId);
  };

  useEffect(() => {
    if (cardId && !businessCardDetails) {
      handleGetBusinessDetailsByCardId(cardId);
    }
  }, [cardId, businessCardDetails]);

  return (
    <ThemeProvider theme={checkoutTheme}>
      <CssBaseline />
      <Grid container sx={{ height: { xs: "100%", sm: "100dvh" } }}>
        <Grid
          item
          xs={12}
          sm={5}
          lg={4}
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            backgroundColor: "background.paper",
            borderRight: { sm: "none", md: "1px solid" },
            borderColor: { sm: "none", md: "divider" },
            alignItems: "start",
            pt: 4,
            px: 10,
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              height: 150,
            }}
          >
            <Button
              startIcon={<ArrowForwardRounded sx={{ ml: 1 }} />}
              component="a"
              href={`/card/${selectedProduct.cardId}`}
              sx={{ mr: "-8px" }}
            >
              חזרה
              <img
                src={
                  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e6faf73568658154dae_SitemarkDefault.svg"
                }
                style={logoStyle}
                alt="Sitemark's logo"
              />
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              width: "100%",
              maxWidth: 500,
            }}
          >
            <Info selectedProduct={selectedProduct} />
          </Box>
        </Grid>
        <Grid
          item
          sm={12}
          md={7}
          lg={8}
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%",
            width: "100%",
            backgroundColor: { xs: "transparent", sm: "background.default" },
            alignItems: "start",
            pt: { xs: 2, sm: 4 },
            px: { xs: 2, sm: 10 },
            gap: { xs: 4, md: 8 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: { sm: "space-between", md: "flex-end" },
              alignItems: "center",
              width: "100%",
              maxWidth: { sm: "100%", md: 600 },
            }}
          >
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Button
                startIcon={<ArrowForwardRounded sx={{ ml: 1 }} />}
                component="a"
                href={`/b/${selectedProduct.businessId}`}
                sx={{ alignSelf: "start" }}
              >
                חזרה
                <img
                  src={logo.full}
                  style={{ ...logoStyle, width: "75px" }}
                  alt="Sitemark's logo"
                />
              </Button>
              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-end",
                flexGrow: 1,
                height: 150,
              }}
            >
              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
              <Stepper
                id="desktop-stepper"
                activeStep={activeStep}
                sx={{
                  width: "100%",
                  height: 40,
                }}
              >
                {steps.map((label) => (
                  <Step
                    sx={{
                      ":first-child": { pr: 0 },
                      ":last-child": { pl: 0 },
                    }}
                    key={label}
                  >
                    <StepLabel
                      StepIconProps={{
                        sx: {
                          ml: 0.5,
                        },
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
          <Card
            sx={{
              display: { xs: "flex", md: "none" },
              width: "100%",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                ":last-child": { pb: 2 },
              }}
            >
              <div>
                <Typography variant="subtitle2" gutterBottom>
                  סה"כ
                </Typography>
                <Typography variant="body1">
                  {`${selectedProduct.price} ש"ח`}
                </Typography>
              </div>
              <InfoMobile selectedProduct={selectedProduct} />
            </CardContent>
          </Card>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              width: "100%",
              maxWidth: { sm: "100%", md: 850 },
              maxHeight: "720px",
              gap: { xs: 5, md: "none" },
            }}
          >
            <Stepper
              id="mobile-stepper"
              activeStep={activeStep}
              alternativeLabel
              sx={{ display: { sm: "flex", md: "none" }, direction: "rtl" }}
            >
              {steps.map((label) => (
                <Step
                  sx={{
                    ":first-child": { pl: 0 },
                    ":last-child": { pr: 0 },
                    "& .MuiStepConnector-root": {
                      top: { xs: 6, sm: 12 },
                      right: "calc(-50% + 20px)",
                      left: "calc(50% + 20px)",
                    },
                  }}
                  key={label}
                >
                  <StepLabel
                    sx={{
                      ".MuiStepLabel-labelContainer": { maxWidth: "70px" },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
              <Stack spacing={2} useFlexGap>
                <Typography variant="h1">📦</Typography>
                <Typography variant="h5">אנו מודים לך!</Typography>
                <Typography variant="body1" color="text.secondary">
                  זה המספר המזהה של התור שקבעת
                  <strong>&nbsp;#140396</strong>. שלחנו לך את זה גם בהודעה למספר
                  שהזנת, בעתיד תוכל לצפות בתור זה באמצעות המספר המזהה ומספר
                  הטלפון שלך.
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    alignSelf: "start",
                    width: { xs: "100%", sm: "auto" },
                  }}
                >
                  צפיה בתור
                </Button>
              </Stack>
            ) : (
              <>
                {getStepContent(activeStep, {
                  selectedProduct,
                })}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column-reverse", sm: "row" },
                    justifyContent:
                      activeStep !== 0 ? "space-between" : "flex-end",
                    alignItems: "end",
                    flexGrow: 1,
                    gap: 1,
                    pb: { xs: 12, sm: 0 },
                    mt: { xs: 2, sm: 0 },
                    mb: "60px",
                  }}
                >
                  {activeStep !== 0 && (
                    <Button
                      // startIcon={<ChevronRightRounded />}
                      onClick={handleBack}
                      variant="text"
                      sx={{
                        display: { xs: "none", sm: "flex" },
                      }}
                    >
                      הקודם
                    </Button>
                  )}

                  {activeStep !== 0 && (
                    <Button
                      // startIcon={<ChevronRightRounded />}
                      onClick={handleBack}
                      variant="outlined"
                      fullWidth
                      sx={{
                        display: { xs: "flex", sm: "none" },
                      }}
                    >
                      הקודם
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    endIcon={!matchSmDown && <ChevronLeftRoundedIcon />}
                    onClick={handleNext}
                    sx={{
                      width: { xs: "100%", sm: "fit-content" },
                    }}
                  >
                    {activeStep === steps.length - 1 ? "קביעת התור" : "המשך"}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
