import { LogoutOutlined } from "@mui/icons-material"
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material"
import { useLocation } from "react-router-dom"
import { useMicrospecDynamicUIContext } from "../../GlobalProvider"
import { useMicrospecThemeLanguageContext } from "../../Themes"
import DrawerListItems from "./DrawerListItems"

const DrawerCommonContent = ({ data }) => {
    const location = useLocation()
    const currentPath = location.pathname
    const { cloudProvider } = useMicrospecDynamicUIContext()
    const { localizationProvider } = useMicrospecThemeLanguageContext()

    const filterPredicate = ((value) => value.common)
    return (
        <List>
            <DrawerListItems data={data} filterPredicate={filterPredicate} />
            <ListItem button key="logout" onClick={() => cloudProvider.signOut(currentPath)}>
                <ListItemIcon>
                    <LogoutOutlined />
                </ListItemIcon>
                <ListItemText
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    primary={localizationProvider.translateByType({
                        type: "Internal",
                        text: "logout"
                    })} />
            </ListItem>
        </List>
    )
}
export default DrawerCommonContent