import { useMemo } from "react";
import { itemListReduce } from "../../Helpers/Pages";
import DrawerListParentItem from "./DrawerListParentItem";
const DrawerListItems = ({ data, filterPredicate }) => {
    const { dashboard } = data
    const itemsMap = useMemo(() => {
        const reduced = itemListReduce(dashboard.items);
        return reduced;
    }, [dashboard.items]);

    return (
        Object.values(itemsMap)
            .filter(filterPredicate)
            .filter((value) => !value.hide)
            .map((value) => <DrawerListParentItem key={value.path} value={value} data={data} />)
    );
}
//
export default DrawerListItems