import { useMemo } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthRoute from "../Auth/AuthRoute";
import ForgotPassword from "../Auth/ForgotPassword";
import Login from "../Auth/Login";
import Register from "../Auth/Register";
import Dashboard from "../Dashboard/Dashboard";
import { addUserSettings } from "../Dashboard/Drawer/CommonElements/UserSettings";
import getDashboardElement from "../Dashboard/ElementMapper";
import getPagesElement from "../Pages/ElementsMapper";
import { verifyNonNull } from "./Utils";

let elements;

const ApplicationRoutes = ({ config }) => {
    const memoizedElements = useMemo(() => {
        elements = []
        handleApplication(config)
        handlePages(config);
        handleAuth(config)
        handleDashboards(config);
        handle404();
        return elements
    }, [config]);
    return useRoutes(memoizedElements)
}

const handleApplication = (config) => {
    config.application = getOrDefault(config.application, {})
    config.application.name = getOrDefault(config.application.name, "AppName")
    document.title = config.application.name
    config.application.auth = getOrDefault(config.application.auth, {})
    config.application.auth.enableRegister = config.application.auth.enableRegister ? true : false
}

const handlePages = (config) => {
    const pages = config.pages = getOrDefault(config.pages, [])
    for (let index = 0; index < pages.length; index++) {
        handleSinglePage(pages[index])
    }
}

const handleSinglePage = (page) => {
    verifyPage(page)
    elements.push({
        path: page.path,
        element: page.auth.enabled ? <AuthRoute path={page.path} element={page.element} roles={page.auth.roles} /> : page.element
    })
}

const verifyPage = (page) => {
    verifyString(page.path, "Path must be specified and of type string.")
    verifyAuth(page)
    verifyPageSpec(page)
}

const verifyAuth = (obj) => {
    obj.auth = getOrDefault(obj.auth, {})
    obj.auth.enabled = getOrDefault(obj.auth.enabled, false)
    obj.auth.roles = getOrDefault(obj.auth.roles, [])
}

const verifyPageSpec = (obj) => {
    obj.spec = getOrDefault(obj.spec, {})
    obj.spec.type = getOrDefault(obj.spec.type, "Simple")
    obj.spec.object = getOrDefault(obj.spec.object, <h1>Start building an awesome groovy page.</h1>)
    obj.element = getPagesElement(obj.spec)
}

const verifyString = (str, message) => {
    if (!str) {
        throw new Error(message)
    }
}

const handleAuth = (config) => {
    handleLogin(config)
    handleRegister(config)
    handleForgotPassword(config)
}

const handleLogin = (config) => {
    elements.push({
        path: "/login",
        element: <Login config={config} />
    })
}

const handleRegister = (config) => {
    const enableRegister = config.application.auth.enableRegister
    if (enableRegister) {
        elements.push({
            path: "/register",
            element: <Register />
        })
    }
}

const handleForgotPassword = (config) => {
    elements.push({
        path: "/forgot",
        element: <ForgotPassword config={config} />
    })
}

const handleDashboards = (config) => {
    const application = config.application
    const dashboards = config.dashboards = getOrDefault(config.dashboards, [])
    for (let index = 0; index < dashboards.length; index++) {
        const dashboard = dashboards[index]
        verifyNonNull(dashboard, "Dashboard")
        handleSingleDashboard(application, dashboard)
    }
}

const handleSingleDashboard = (application, dashboard) => {
    verifyDashboard(application, dashboard)
}

const verifyDashboard = (application, dashboard) => {
    verifyString(dashboard.path, "Path must be specified and of type string.")
    verifyRoles(dashboard)
    verifyDashboardItems(application, dashboard)
}

const handleDashboardItemSpec = (application, dashboard, items) => {
    const itemsLastIndex = items.length - 1
    items[itemsLastIndex].element = getDashboardElement(items[itemsLastIndex].spec)
    addItemPath(application, dashboard, items);
}

const handleDashboardItemSpecs = (application, dashboard, item) => {
    const specs = item.specs = getOrDefault(item.specs, []);
    for (const internalItem of specs) {
        handleDashboardItemSpec(application, dashboard, [item, internalItem]);
    }
}

const verifyRoles = (dashboard) => {
    dashboard.roles = getOrDefault(dashboard.roles, [])
}

const verifyDashboardItems = (application, dashboard) => {
    const items = dashboard.items = getOrDefault(dashboard.items, [])
    addCommonItems(dashboard.items)
    for (let index = 0; index < items.length; index++) {
        const item = items[index];
        verifyString(item.path, "Path must be specified and of type string.")
        verifyString(item.name, "Name must be specified and of type string.")
        verifyNonNull(item.icon, "Icon")
        const isSingleSpec = verifyItemSpecAndSpecsMutualExclusivity(item);
        isSingleSpec ? handleDashboardItemSpec(application, dashboard, [item]) : handleDashboardItemSpecs(application, dashboard, item);
    }
    handleDashboardBasePath(dashboard, items[0])
}

const verifyItemSpecAndSpecsMutualExclusivity = (item) => {
    if (item.spec && item.specs) {
        console.error("Item cannot have both spec and specs.");
        console.error("Item: ", item);
        throw new Error("Item cannot have both spec and specs.")
    }
    if (!item.spec && !item.specs) {
        console.error("Item must have either spec or specs.");
        console.error("Item: ", item);
        throw new Error("Item must have either spec or specs.")
    }
    if (item.spec) {
        return true;
    }
    if (item.specs) {
        return false;
    }
}

const handleDashboardBasePath = (dashboard, item) => {
    const basePathElementSpec = dashboard.basePathElementSpec = getOrDefault(dashboard.basePathElementSpec, {})
    basePathElementSpec.path = dashboard.path

    const dashboardMainElement = {
        path: dashboard.path,
        spec: basePathElementSpec
    }
    if (basePathElementSpec.type && basePathElementSpec.object) {
        handleSinglePage(dashboardMainElement)
    } else if (item) {
        elements.push({
            path: dashboardMainElement.path,
            element: <Navigate to={item.fullPath} replace={true} />
        })
    }
}

const addItemPath = (application, dashboard, items) => {
    let itemFullPath = dashboard.path
    for (let item of items) {
        itemFullPath += "/" + item.path
    }
    const itemsLastIndex = items.length - 1
    items[itemsLastIndex].fullPath = itemFullPath;
    elements.push({
        path: itemFullPath,
        element: <AuthRoute
            path={itemFullPath}
            element={
                <Dashboard
                    application={application}
                    dashboard={dashboard}
                    parent={items[0]}
                    item={items[itemsLastIndex]}
                />
            }
            roles={dashboard.roles} />,
    })
}

const handle404 = () => {
    elements.push({
        path: "*",
        element: getPagesElement({ type: "NotFound" }),
    })
}

const addCommonItems = (itemsList) => {
    addUserSettings(itemsList)
}

const getOrDefault = (obj, def) => {
    return obj ? obj : def
}

export default ApplicationRoutes;