import { motion } from "framer-motion";
import React from "react";
import { useMicrospecThemeLanguageContext } from "../../../MicrospecDynamicUI/Themes";
import "../../pages/BusinessCardLandingPage/index.css";
import styles from "../../pages/BusinessCardLandingPage/style";
import Button from "./Button";

const CTA = ({ scaleUpVariants }) => {
  const { localizationProvider } = useMicrospecThemeLanguageContext();
  return (
    <motion.section
      className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} sm:flex-row flex-col bg-black-gradient-2 rounded-[20px] box-shadow items-center`}
      variants={scaleUpVariants}
      initial={scaleUpVariants.hidden}
      whileInView={scaleUpVariants.whileInView}
      viewport={scaleUpVariants.viewport}
    >
      <div className="flex flex-col flex-1">
        <h2 className={styles.heading2}>צור את כרטיס הביקור הדיגיטלי שלך!</h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          צור את כרטיס הביקור הדיגיטלי המוביל בשוק עוד היום והתחל להיחשף לקהל
          חדש ולקוחות חדשים.
        </p>
      </div>

      <div className={`${styles.flexCenter} sm:ml-10 ml-0 my-5 sm:my-0`}>
        <Button
          type="button"
          text={`${localizationProvider.translateByType({
            type: "Localized",
            text: "business-cards.get",
          })} ${localizationProvider.translateByType({
            type: "Localized",
            text: "business-cards.started",
          })}`}
          href={'/login?redirectTo=/business/cards'}
        />
      </div>
    </motion.section>
  );
};

export default CTA;
