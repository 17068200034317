import { Dialog, DialogTitle, Divider } from "@mui/material";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import { useState } from "react";
const OfflineDialog = ({ config }) => {

    const { application } = config
    const { commonConfig } = application
    const { showOfflineDialog } = commonConfig
    
    const [isOnline, setIsOnline] = useState(window.navigator.onLine)
    useEnhancedEffect(() => {
        // Update network status
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
        };

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);

    return (
        <Dialog fullWidth open={!isOnline && showOfflineDialog}>
            <DialogTitle variant="h1" sx={{ alignSelf: "center" }}>You are offline</DialogTitle>
            <Divider />
            {/* {bg} */}
            {/* <img src={bg} alt="offline " /> */}
            <Divider />
            <DialogTitle variant="subtitle1" sx={{ alignSelf: "center" }}>Please wait until you come online</DialogTitle>
        </Dialog>
    )
}

export default OfflineDialog