import { Box, CssBaseline } from "@mui/material";
import { useState } from "react";
import DashboardAppbar from "./DashboardAppbar";
import DashboardContent from "./DashboardContent";
import DashboardDrawer from "./Drawer/DashboardDrawer";
const drawerWidth = 240;

const Dashboard = ({ application, dashboard, parent, item }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DashboardAppbar data={{ handleDrawerToggle, item }} />
      <DashboardDrawer
        data={{
          handleDrawerToggle,
          mobileOpen,
          application,
          dashboard,
          parent,
          item
        }}
      />
      <DashboardContent data={{ item }} />
    </Box>
  );
};

export default Dashboard;
export { drawerWidth };

