import { cloneElement, isValidElement } from "react"
import CallToActionBanner from "./CallToActionBanner"
import CollapsableFaqWithFloatingPhoto from "./CollapsableFaqWithFloatingPhoto"
import DescriptionWithProgressBarAside from "./DescriptionWithProgressBarAside"
import EllipsePhotoWithParagaphAside from "./EllipsePhotoWithParagaphAside"
import FieldsOfExperience from "./FieldsOfExperience"
import FullScreenTitledImageSection from "./FullScreenTitledImageSection"
import HeroCTACard from "./HeroCTACard"
import NewsLetterCard from "./NewsLetterCard"
import RoundedImageAndTextSquareAside from "./RoundedImageAndTextSquareAside"
import ServicesAsymmetricGrid from "./ServicesAsymmetricGrid"
import TitledIconedCardsGrid from "./TitledIconedCardsGrid"
import HeroSectionWithScrollTrigger from "./HeroSectionWithScrollTrigger"
import StackingCards from "./StackingCards"
import FooterWithCallToActionBanner from "./FooterWithCallToActionBanner"
import CardWithTextAndCountersSection from "./CardWithTextAndCountersSection"
import Navbar from "./Navbar"
import Footer from "./Footer"
import Statistics from "./Statistics"
import ContactForm from "./ContactForm"
import SectionsDivider from "./SectionsDivider"

const specToSectionMapper = {
    // Above Done
    SectionsDivider: <SectionsDivider />,
    Navbar: <Navbar />,
    HeroSectionWithScrollTrigger: <HeroSectionWithScrollTrigger />,
    Statistics: <Statistics />,
    CallToActionBanner: <CallToActionBanner />,
    CollapsableFaqWithFloatingPhoto: <CollapsableFaqWithFloatingPhoto />,
    DescriptionWithProgressBarAside: <DescriptionWithProgressBarAside />,
    EllipsePhotoWithParagaphAside: <EllipsePhotoWithParagaphAside />,
    FieldsOfExperience: <FieldsOfExperience />,
    HeroCTACard: <HeroCTACard />,
    NewsLetterCard: <NewsLetterCard />,
    RoundedImageAndTextSquareAside: <RoundedImageAndTextSquareAside />,
    ServicesAsymmetricGrid: <ServicesAsymmetricGrid />,
    FullScreenTitledImageSection: <FullScreenTitledImageSection />,
    TitledIconedCardsGrid: <TitledIconedCardsGrid />,
    StackingCards: <StackingCards />,
    CardWithTextAndCountersSection: <CardWithTextAndCountersSection />,
    FooterWithCallToActionBanner: <FooterWithCallToActionBanner />,
    ContactForm: <ContactForm />,
    Footer: <Footer />
}

const getSectionsMapper = (spec) => {
    const section = specToSectionMapper[spec.type]
    const processed = isValidElement(section) ? cloneElement(section, spec) : section
    return processed
}

export default getSectionsMapper