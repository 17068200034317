import PropTypes from "prop-types";
import * as React from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

function Info({  selectedProduct }) {
  return (
    <React.Fragment>
      <Typography sx={{ direction: "rtl" }} variant="subtitle2" color="text.secondary">
        סה"כ
      </Typography>
      <Typography sx={{ direction: "rtl" }} variant="h4" gutterBottom>
        {`${selectedProduct.price} ש"ח`}
      </Typography>
      <List disablePadding sx={{ direction: "rtl" }}>
        {/* {products.map((product) => ( */}
        <ListItem key={selectedProduct.name} sx={{ py: 1, px: 0 , textAlign: "start"}}>
          <ListItemText
            sx={{ mr: 2 }}
            primary={selectedProduct.name}
            secondary={selectedProduct.desc}
          />
          <Typography
            variant="body1"
            fontWeight="medium"
          >
            {`${selectedProduct.price} ש"ח`}
          </Typography>
        </ListItem>
        {/* ))} */}
      </List>
    </React.Fragment>
  );
}

Info.propTypes = {
  selectedProduct: PropTypes.object.isRequired,
};

export default Info;
