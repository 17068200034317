import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { itemListReduce } from "../../Helpers/Pages";
import { useMicrospecThemeLanguageContext } from "../../Themes";

const DrawerListChildrenItems = ({ data }) => {
    const navigate = useNavigate()
    const location = useLocation();
    const { localizationProvider } = useMicrospecThemeLanguageContext();
    const { parent, childrenOpen } = data
    const itemsMap = useMemo(() => itemListReduce(parent.specs), [parent.specs]);

    return (
        <Collapse in={childrenOpen} timeout="auto" unmountOnExit>
            <List
                component="div"
                sx={{
                    marginInlineStart: 1,
                    marginInlineEnd: 0,
                    paddingInlineStart: 2,
                    paddingInlineEnd: 0,
                    my: 0,
                    py: 0
                }}
            >
                {
                    Object.values(itemsMap).map((childItem) => {
                        return (
                            <ListItemButton
                                key={childItem.path + "list-internal-item"}
                                selected={childItem.fullPath === location.pathname}
                                onClick={() => {
                                    if (childItem.fullPath !== location.pathname) {
                                        navigate(childItem.fullPath, { replace: true })
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    {childItem.icon}
                                </ListItemIcon>
                                <ListItemText
                                    id={childItem.path + "list-internal-item-text"}
                                    key={childItem.path + "list-internal-item-text"}
                                    name={childItem.path + "list-internal-item-text"}
                                    primary={localizationProvider.translateByType(childItem.name)}
                                    style={{ display: "flex", justifyContent: "flex-start" }}
                                />

                            </ListItemButton>
                        )
                    })
                }
            </List>
        </Collapse>
    );
}

export default DrawerListChildrenItems;